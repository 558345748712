@tailwind base;
@tailwind components;
@tailwind utilities;

.will-change-transform {
  will-change: transform;
}

.cursor::after {
  display: block;
  content: '';
  position: absolute;
  width: 4px;
  height: 100%;
  background-color: #000;
  animation: cursor 0.6s linear infinite alternate;
  will-change: opacity;
}
 
@keyframes cursor {
  0%,
  40% {
    opacity: 1;
  }
 
  60%,
  100% {
    opacity: 0;
  }
}