:root {
  --text: #2b3044;
  --line: #000000;
  --line-active: #000000;
}

.dark-mode {
  --line: #FFFFFF;  /* Change SVG line color in dark mode */
  --line-active: #FFFFFF;  /* Change SVG line color on hover in dark mode */
}

a {
  display: inline-block;
  position: relative;
  text-decoration: none;
  color: inherit;
  margin: 0 var(--spacing, 0px);
  transition: margin 0.25s;
}

svg {
  width: 76px;
  height: 40px;
  position: absolute;
  left: 50%;
  bottom: 0;
  transform: translate(-50%, 7px) translateZ(0);
  fill: none;
  stroke: var(--stroke, var(--line));
  stroke-linecap: round;
  stroke-width: 2px;
  stroke-dasharray: var(--offset, 69px) 278px;
  stroke-dashoffset: 361px;
  transition: stroke 0.25s ease var(--stroke-delay, 0s), stroke-dasharray 0.35s;
}

a:hover {
  --spacing: 4px;
  --stroke: var(--line-active);
  --stroke-delay: 0.1s;
  --offset: 180px;
}

*:before,
*:after {
  box-sizing: inherit;
}/*# sourceMappingURL=linkHover.css.map */


/*:root {
  --text: #2B3044;
  --line: #BBC1E1;
  --line-active: #275EFE;
}

p {
  font-size: 18px;
  margin: 0;
  color: var(--text);
  a {
      display: inline-block;
      position: relative;
      text-decoration: none;
      color: inherit;
      margin: 0 var(--spacing, 0px);
      transition: margin .25s;
      svg {
          width: 76px;
          height: 40px;
          position: absolute;
          left: 50%;
          bottom: 0;
          transform: translate(-50%, 7px) translateZ(0);
          fill: none;
          stroke: var(--stroke, var(--line));
          stroke-linecap: round;
          stroke-width: 2px;
          stroke-dasharray: var(--offset, 69px) 278px;
          stroke-dashoffset: 361px;
          transition: stroke .25s ease var(--stroke-delay, 0s), stroke-dasharray .35s;
      }
      &:hover {
          --spacing: 4px;
          --stroke: var(--line-active);
          --stroke-delay: .1s;
          --offset: 180px;
      }
  }
}

html {
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
}

* {
  box-sizing: inherit;
  &:before,
  &:after {
      box-sizing: inherit;
  }
}*/